.About-main {
  height: 100%;
  padding: 100px 150px;
  margin-top: 80px;
}

@media (max-width: 768px) {
  .About-main {
    height: 100%;
    padding: 30px;
    margin-top: 80px;
  }
}
