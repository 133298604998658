.profile-pin {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pin {
  position: relative;
  width: 100px;
  height: 120px;
  clip-path: path(
    "M 50,0 C 75,0 100,25 100,50 C 100,75 75,100 50,120 C 25,100 0,75 0,50 C 0,25 25,0 50,0 Z"
  );
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.profile-image-container {
  margin-top: 10px;
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  filter: grayscale(1);
}

.profile-name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.profile-title {
  font-size: 14px;
  margin-top: 0px;
  color: gray;
}
