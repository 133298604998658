.ImageComponent-main {
  width: 100%;
  min-height: 450px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageComponent-main img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
}

.ImageComponent-main h1 {
  color: #ffffffb2;
  z-index: 1;
  font-size: 64px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .ImageComponent-main {
    width: 100%;
    min-height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ImageComponent-main img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -1;
  }

  .ImageComponent-main h1 {
    color: #ffffffb2;
    z-index: 1;
    font-size: 34px;
    font-weight: 600;
  }
}
