.LandImage-main {
  width: 100%;
  height: 100%;
  background-color: wheat;
  border-radius: 8px;
}

.LandImage-main img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .LandImage-main {
    height: 250px;
  }
}
