.Content-main {
  max-width: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Content-main p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: "Open Sans";
  margin-block-start: 0;
  margin-block-end: 7px;
}

.Content-main img {
  width: 100%;
  object-fit: fill;
  margin-bottom: 20px;
}
