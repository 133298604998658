.connect-btn {
  padding: 20px 50px;
  border: 2px solid #cb7f00;
  border-radius: 70px;

  backdrop-filter: blur(4px);
  background-color: #38260036;

  color: white;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.5px;

  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease,
    backdrop-filter 0.3s ease;
}

.connect-btn:hover,
.connect-btn.clicked {
  background-color: #ffad00;
  backdrop-filter: none;
  color: #0f491c;

  border: 2px solid #cb7f00;
}

.connect-btn.disabled {
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .connect-btn {
    padding: 16px 42px;

    font-size: 20px;
  }
}
