.nav-button {
  width: 190px;
  padding: 20px 50px;

  border: 2px solid #cb7f00;
  border-radius: 350px;
  backdrop-filter: blur(4px);
  background-color: #38260036;

  color: white;
  text-align: center;
  font-size: 25px;
  letter-spacing: 1.5px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;

  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease,
    backdrop-filter 0.3s ease, border-color 0.3s ease;
}

.nav-button.clicked {
  background-color: #ffad00;
  backdrop-filter: none;
  color: #0f491c;

  border: 2px solid #cb7f00;
}

@media only screen and (max-width: 1700px) {
  .nav-button {
    width: 130px;
    padding: 18px 46px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 1320px) {
  .nav-button {
    width: 120px;
    padding: 16px 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .nav-button {
    width: 160px;
    padding: 16px 42px;

    font-size: 20px;
  }
}
