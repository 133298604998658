.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  transition: background-color 0.25s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar.transparent {
  background-color: transparent;
}

.navbar.solid {
  background-color: #2f2929;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-right: 30px;
  padding-left: 30px;
}

.nav-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-links {
  display: flex;
  list-style: none;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.nav-links a.active {
  color: #ff9b00; /* Highlight active link */
  font-weight: bold;
}

.nav-links a.inactive {
  color: white; /* Highlight active link */
}

.sign-up {
  background-color: #1e6f30;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 50px;
  font-family: "Comfortaa";
}

.sign-up:hover {
  background-color: darkgreen;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links-container {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #2f2929;
    transition: background-color 0.3s ease-in-out, max-height 0.3s ease-in-out,
      padding 0.3s ease-in-out;
    padding: 0px 0;
  }

  .nav-links-container.transparent {
    background-color: transparent;
  }

  .nav-links-container.solid {
    background-color: #2f2929;
  }

  .nav-links-container.open {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    gap: 15px;
  }

  .nav-links a {
    font-size: 18px;
  }
}
