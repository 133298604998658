.contact-main-cont {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: 500px;
  padding: 30px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;

  background-color: #594b2e4d;
  border: 2px solid #ffad001a;

  border-radius: 30px;
}

.contact-title {
  font-size: 24px;
  font-weight: 700;
  color: #ffad00;
}

.contact-input-box {
  width: 100%;

  margin-bottom: 15px;

  display: flex;
  flex-direction: column;
}

.contact-input-box label {
  font-size: small;
  margin-left: 10px;
  margin-bottom: 5px;
}

.contact-main-cont input,
.contact-main-cont textarea {
  box-shadow: 0px 0px 0px 2px rgba(247, 247, 247, 0.8);
  background-color: rgba(247, 247, 247, 0.8);
  transition: 0.1s ease-in-out;
  border-radius: 10px;
  padding: 8px 10px;
  font-size: 15px;
  outline: none;
  border: none;

  font-family: "Comfortaa", sans-serif;
}

.contact-main-cont textarea {
  resize: none;
}

.contact-btn {
  width: 130px;
  height: 42px;
  border-radius: 80px;

  padding: 3px 6px;
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1e6f30;

  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 700;

  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    backdrop-filter 0.3s ease;
}

.contact-btn:hover {
  background-color: #ffad00;
  backdrop-filter: none;
  color: black;
  font-weight: 700;
}

.contact-err-text {
  font-size: small;
  color: #ffad00;
  margin-top: 5px;
  margin-left: 10px;
}

.back-btn {
  width: 80px;
  height: 42px;
  border-radius: 80px;

  padding: 3px 6px;
  margin-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1e6f30;

  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 700;

  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    backdrop-filter 0.3s ease;
}

.back-btn:hover {
  background-color: #ffad00;
  backdrop-filter: none;
  color: black;
  font-weight: 700;
}

.top-left-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  filter: drop-shadow(0px 1px 0px #ffad00);
}

@media only screen and (max-width: 600px) {
  .contact-main-cont {
    min-width: 10%;
    width: 90vw;
    padding: 20px 10px;

    background: linear-gradient(
      -30deg,
      rgba(89, 75, 46, 0.34) 64%,
      rgba(89, 75, 46, 0) 100%
    );
    border: none;
    top: 45%;
  }

  .contact-title {
    font-size: 20px;
    font-weight: 700;
  }

  .contact-err-text {
    font-size: x-small;
  }
}
