.ImpactAndVision-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.ImpactAndVision-main h2 {
  width: 100%;
  text-align: left;
  font-size: 32px;
}

.ImpactAndVision-main p {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.ImpactAndVision-main button {
  background-color: #1e6f30;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Comfortaa";
  width: 170px;
  min-height: 56px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .ImpactAndVision-main h2 {
    text-align: center;
  }
  .ImpactAndVision-main {
    margin-top: 20px;
  }
}
