@keyframes buttonFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 173, 1, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 173, 1, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 173, 1, 0.5);
  }
}

@keyframes fadeInFromBackground {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animation-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeInFromBackground 3s ease-out;
}

.logo-and-text p {
  color: white;
  font: Open Sans;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  animation: fadeInFromBackground 2s ease-out;
}

.logo-and-text button {
  background-color: #ffad01;
  color: #1a6221;
  padding: 24px 42px 24px 52px;
  border-radius: 180px;
  font: Open Sans;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  animation: buttonFadeIn 3s ease-out, pulse 2s infinite;
}

.logo-and-text button:hover {
  cursor: pointer;
}

.lottie-title {
  opacity: 1;
  animation: fadeInFromBackground 1s ease-out;
  text-align: center;
  color: white;
  font: Open Sans;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
}

.lottie-text {
  opacity: 1;
  transition: opacity 1s ease-in-out;
  animation: fadeInFromBackground 1s ease-out;
  text-align: center;
  color: white;
  font: Open Sans;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
}

.lottie-text.fade-in {
  animation: fadeInOpacity 1s ease-in-out forwards;
}

.lottie-text.fade-out {
  animation: fadeOutOpacity 1s ease-in-out forwards;
}

.lottie-animation {
  opacity: 1;
  transition: opacity 1s ease-in-out;
  animation: fadeInFromBackground 1s ease-out;
}

.lottie-animation.fade-in {
  animation: fadeInOpacity 1s ease-in-out forwards;
}

.lottie-animation.fade-out {
  animation: fadeOutOpacity 1s ease-in-out forwards;
}

@media only screen and (max-width: 600px) {
  .logo-and-text p {
    font-size: 26px;
  }

  .logo-and-text button {
    padding: 16px 32px 16px 32px;
    font-size: 18px;
  }

  .lottie-title {
    opacity: 1;
    animation: fadeInFromBackground 1s ease-out;
    text-align: center;
    color: white;
    font: Open Sans;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
  }

  .lottie-text {
    opacity: 1;
    transition: opacity 1s ease-in-out;
    animation: fadeInFromBackground 1s ease-out;
    text-align: center;
    color: white;
    font: Open Sans;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
  }
}
