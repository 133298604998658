.ProjectsImage-main {
  width: 100%;
  height: 100%;

  border-radius: 8px;
}

.ProjectsImage-main img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .ProjectsImage-main {
    height: 300px;
  }
}
