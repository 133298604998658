.About-container {
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
}

.About-left {
  width: 40%;
  padding-left: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.About-left h2 {
  font-size: 40px;
  color: #432d27;
  text-align: left;
  width: 100%;
}

.About-left p {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  color: #6c6665;
  width: 100%;
}

.About-right {
  width: 60%;
  padding-right: 150px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.About-left button {
  background-color: #1e6f30;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Comfortaa";
  width: 170px;
  height: 56px;
}

.Item-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Item-container h3 {
  color: #432d27;
  font-size: 20px;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 16px;
}

.Item-container p {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  color: #6c6665;
  width: 100%;
}

@media (max-width: 768px) {
  .About-container {
    width: auto;
    min-height: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .About-left {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .About-left h2 {
    font-size: 32px;
    color: #432d27;
    text-align: center;
    width: 100%;
  }

  .About-left p {
    font-size: 18px;
    text-align: center;
  }

  .About-right {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    gap: 30px;
  }

  .Item-container {
    align-items: center;
  }

  .Item-container h3 {
    text-align: center;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 16px;
  }

  .Item-container p {
    text-align: center;
  }
}
