.DropdownImage-main {
  max-width: 920px;
  width: 100%;
  min-height: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropdownImage-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
}

.DropdownImage-main h2 {
  color: #ffffff;
  z-index: 1;
  font-size: 32px;
  font-weight: 500;
  z-index: 1;
}

.DropdownImage-arrow {
  transition: transform 0.3s ease;
  object-fit: fill;
}

.DropdownImage-arrow.open {
  transform: rotate(-180deg);
}

.DropdownImage-arrow-container {
  position: absolute;
  top: 40%;
  left: 10%;
  z-index: 1;
  display: inline-block;
  cursor: pointer;
}

@media (max-width: 768px) {
  .DropdownImage-main {
    max-width: none;
  }

  .DropdownImage-main h2 {
    font-size: 24px;
    font-weight: 600;
    width: 40%;
    text-align: center;
  }

  .DropdownImage-arrow-container {
    left: 5%;
  }
}
