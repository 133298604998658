.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  border-radius: 8px;
}

.contact-form-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.contact-form-container input,
.contact-form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
}

.contact-form-container button {
  background-color: #1e6f30;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Comfortaa";
  width: 100%;
}

.contact-form-container button:hover {
  background-color: #155a24;
}
