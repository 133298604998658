.Organizations-main {
  width: 100%;
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .Organizations-main img {
    width: 120px;
    height: 120px;
  }
  .Organizations-main {
    gap: 20px;
  }
}
