.Widget-container {
  width: 100%;
  min-height: 450px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 60px;
  gap: 100px;
}

.Image-container {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image-container.left {
  padding-left: 150px;
}

.Image-container.right {
  padding-right: 150px;
}

.Image-container img {
  overflow: hidden;
  border-radius: 16px;
}

.Content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 25px;
}

.Content-container.left {
  align-items: flex-start;
  padding-right: 150px;
}

.Content-container.right {
  align-items: flex-end;
  padding-left: 150px;
  text-align: right;
}

.Content-container h2 {
  font-size: 32px;
}

.Content-container p {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.Content-container button {
  background-color: #1e6f30;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Comfortaa";
  min-width: 170px;
  height: 56px;
}

@media (max-width: 768px) {
  .Widget-container {
    width: auto;
    min-height: 0px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 50px;
    flex-direction: column;
  }

  .Image-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Image-container.left {
    padding-left: 0px;
  }

  .Image-container.right {
    padding-right: 0px;
  }

  .Image-container img {
    padding: 20px;
  }

  .Content-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
  }

  .Content-container.left {
    align-items: center;
    padding-right: 0px;
    text-align: center;
  }

  .Content-container.right {
    align-items: center;
    padding-left: 00px;
    text-align: center;
  }

  .Content-container h2 {
    font-size: 32px;
  }

  .Content-container p {
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    width: 100%;
  }
}
