.planet-spline-layer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.5s;
  overflow: hidden; 
}

.planet-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}
