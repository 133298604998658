@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.questionnaire-main-cont {
  position: absolute;
  backdrop-filter: blur(50px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s ease-in-out;
  min-width: 500px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: white;
  background-color: #594b2e4d;
  border: 2px solid #ffad001a;
  border-radius: 30px;
  animation: fadeIn 1s ease-in-out;
}

.questionnaire-title {
  font-size: 20px;
  font-weight: 700;
  color: #ffad00;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
}

.questionnaire-input-box {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.questionnaire-input-box label {
  font-size: medium;
  margin-left: 10px;
  margin-bottom: 5px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
}

.questionnaire-main-cont input,
.questionnaire-main-cont textarea {
  box-shadow: 0px 0px 0px 2px rgba(247, 247, 247, 0.8);
  background-color: rgba(247, 247, 247, 0.8);
  transition: 0.1s ease-in-out;
  border-radius: 10px;
  padding: 8px 10px;
  font-size: 15px;
  outline: none;
  border: none;
  font-family: "Comfortaa", sans-serif;
}

.questionnaire-main-cont select {
  box-shadow: 0px 0px 0px 2px rgba(247, 247, 247, 0.8);
  background-color: rgba(247, 247, 247, 0.8);
  transition: 0.1s ease-in-out;
  border-radius: 10px;
  padding: 8px 10px;
  font-size: 15px;
  outline: none;
  border: none;
  font-family: "Comfortaa", sans-serif;
  margin-top: 10px;
}

.questionnaire-btn {
  width: 130px;
  height: 42px;
  border-radius: 80px;

  padding: 3px 6px;
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1e6f30;

  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 700;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 1);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    backdrop-filter 0.3s ease;
}

.questionnaire-btn:hover {
  background-color: #ffad00;
  backdrop-filter: none;
  color: black;
  font-weight: 700;
}

.questionnaire-err-text {
  font-size: small;
  color: #ffad00;
  margin-top: 8px;
  margin-left: 10px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 1);
}

.top-left-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  filter: drop-shadow(0px 1px 0px #ffad00);
}

.end-message {
  position: absolute;
  backdrop-filter: blur(50px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s ease-in-out;
  min-width: 500px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background-color: #594b2e4d;
  border: 2px solid #ffad001a;
  border-radius: 30px;
  animation: fadeIn 1s ease-in-out;
}

.end-message p {
  text-align: "center";
  color: #ffad00;
  font-size: "18px";
  font-weight: 600;
}

.end-message button {
  padding: 13px 39px 13px;
  border: 2px solid #ffad0099;
  border-radius: 70px;
  color: white;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1;
}

.end-message button:hover {
  background-color: #ffad00;
  backdrop-filter: none;
  color: black;
}

@media only screen and (max-width: 600px) {
  .questionnaire-main-cont {
    min-width: 10%;
    width: 90vw;
    padding: 20px 10px;

    background: linear-gradient(
      -30deg,
      rgba(89, 75, 46, 0.34) 64%,
      rgba(89, 75, 46, 0) 100%
    );
    border: none;
    top: 45%;
  }

  .questionnaire-title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 2rem;
  }

  .questionnaire-err-text {
    font-size: x-small;
  }

  .top-left-icon {
    top: 10px;
  }

  .end-message {
    max-width: 600px;
    min-width: 250px;
    text-align: center;
    font-size: 24px;
  }
}

@media only screen and (max-height: 1080px) {
  .questionnaire-main-cont {
    top: 44%;
  }

  .top-left-icon {
    margin-bottom: 20px;
  }
}
