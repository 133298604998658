@keyframes moveRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(50%);
  }
}

.main-step-content {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12%;
  position: relative;
}

.main-step-content.animate .left {
  animation: moveRight 2s forwards, fadeOut 2s forwards;
}

.main-step-content.animate .right {
  animation: moveLeft 2s forwards, fadeOut 2s forwards;
}

.text {
  letter-spacing: 2px;
  transition: opacity 2s ease;
}

.main-step-span {
  font-size: 20px;
  font-weight: 700;
}

.main-step-span.orange {
  color: #ffad00;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

.main-step-span.white {
  color: #ffffff;
}

.main-step-hover-text {
  position: fixed;
  bottom: 0;
  margin: 2rem;
  width: 30%;
  z-index: 2;
  color: white;

  border-radius: 20px;
  border: 1px solid #ffad01;

  box-sizing: border-box;

  background-color: #38260036;
  backdrop-filter: blur(35px);
}

.main-step-hover-text p {
  padding: 20px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.main-step-hover-text.left {
  left: 2rem;
  background: linear-gradient(
    -30deg,
    rgba(89, 75, 46, 0.34) 34%,
    rgba(89, 75, 46, 0) 100%
  );
}

.main-step-hover-text.right {
  right: 2rem;
  background: linear-gradient(
    30deg,
    rgba(89, 75, 46, 0.34) 34%,
    rgba(89, 75, 46, 0) 100%
  );
}

.main-step-hover-text.fade-in-left {
  animation: fadeInLeft 1s forwards;
}

.main-step-hover-text.fade-out-left {
  animation: fadeOutLeft 1s forwards;
}

.main-step-hover-text.fade-in-right {
  animation: fadeInRight 1s forwards;
}

.main-step-hover-text.fade-out-right {
  animation: fadeOutRight 1s forwards;
}

@media only screen and (max-width: 1200px) {
  .main-step-content {
    flex-direction: column;
    gap: 16%;
  }

  .main-step-hover-text {
    font-size: 10px;
    min-width: 350px;
    height: auto;
    margin: 0 auto;

    background: linear-gradient(
      -30deg,
      rgba(89, 75, 46, 0.34) 34%,
      rgba(89, 75, 46, 0) 100%
    );
    z-index: 100;
    backdrop-filter: blur(50px);
  }

  .main-step-hover-text p {
    padding: 5px 20px;
  }

  .main-step-hover-text.left {
    bottom: 65%;
    left: 0;
    right: 0;
  }

  .main-step-hover-text.right {
    bottom: 15%;
    left: 0;
    right: 0;
  }

  .text.left.hide {
    opacity: 0;
    transition: fadeOut 1s forwards;
  }

  .text.right.hide {
    opacity: 0;
    transition: fadeOut 1s forwards;
  }

  .main-step-span {
    font-size: 18px;
  }

  .text.right {
    width: 80%;
    line-height: 30px;
    text-align: center;
  }

  .main-step-content.animate .left {
    animation: moveDown 1s forwards, fadeOut 1s forwards;
  }

  .main-step-content.animate .right {
    animation: moveUp 1s forwards, fadeOut 1s forwards;
  }

  @keyframes moveDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(50%);
    }
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-50%);
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-step-span {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }

  .main-step-content {
    margin-bottom: 30%;
  }
}
