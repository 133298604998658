.LandingImage-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingImage-main {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  object-fit: fill;
  z-index: -1;
}

.LandingImage-content {
  position: relative;
  color: white;
  z-index: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15%;
}

.LandingImage-content h1 {
  font-size: 64px;
}

.LandingImage-content p {
  font-size: 20px;
  margin-bottom: 40px;
}

.LandingImage-content button {
  background-color: #1e6f30;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: "Comfortaa";
  width: 170px;
  height: 56px;
}

@media (max-width: 768px) {
  .LandingImage-container {
    height: 480px;
  }
  .LandingImage-main {
    height: 480px;
    width: 100%;
  }
  .LandingImage-content h1 {
    font-size: 28px;
    text-align: center;
    margin-top: 100px;
  }

  .LandingImage-content {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .LandingImage-content.second {
    display: none;
  }
  .LandingImage-content button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-family: "Comfortaa";
    width: auto;
    height: auto;
  }
}
