.FourPillars-container {
  width: 100%;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.FourPillars-left {
  width: 30%;
  padding-left: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FourPillars-left h2 {
  font-size: 32px;
  color: #000000;
  text-align: left;
  width: 100%;
}

.FourPillars-left h3 {
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  text-align: left;
  width: 100%;
}

.FourPillars-left p {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;

  width: 100%;
}

.FourPillars-right {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FourPillars-right img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .FourPillars-container {
    min-height: 0px;
    flex-direction: column;
  }
  .FourPillars-left {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .FourPillars-left-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
  }

  .FourPillars-left h2 {
    text-align: center;
  }

  .FourPillars-left h3 {
    text-align: center;
  }

  .FourPillars-left p {
    text-align: center;
  }

  .FourPillars-right {
    width: auto;
  }
}
