.Projects-main {
  height: 100%;
  padding: 30px 150px;
}

.Projects-main h2 {
  width: 100%;
  text-align: center;
  font-size: 32px;
}

.Projects-main p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: "Open Sans";
}

@media (max-width: 768px) {
  .Projects-main {
    height: 100%;
    padding: 30px;
  }
}
