@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.info-card-main-cont {
  position: fixed;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);

  width: 650px;
  height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #38260036;
  backdrop-filter: blur(4px);

  border-radius: 20px;
  border: 1px solid #ffad01;

  color: white;
  text-align: center;

  animation: fadeInUp 0.75s ease-in-out;
}

.info-card-cont {
  height: 100%;
  padding: 5px 25px;
  gap: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-card-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.info-card-btn {
  width: 125px;
  min-height: 45px;
  border-radius: 210px;

  border: 1.17px solid #cb7f00;

  padding: 3px 6px;
  margin-bottom: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffad01;

  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  color: #0f491c;
  font-family: "Open Sans", sans serif;

  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease,
    backdrop-filter 0.3s ease;
}

.info-card-btn:hover {
  background-color: #ffad00;
  backdrop-filter: none;
  color: black;
}

@media only screen and (max-width: 1200px) {
  .info-card-main-cont {
    bottom: 20%;
    width: 75vw;
    height: auto;

    padding: 15px 30px;
    background: linear-gradient(
      -30deg,
      rgba(89, 75, 46, 0.34) 34%,
      rgba(89, 75, 46, 0) 100%
    );
    border: none;
  }

  .info-card-cont {
    padding: 0px;
    gap: 20px;
  }

  .info-card-btn {
    margin-top: 0px;
  }
}
