.Team-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.Team-main h2 {
  width: 100%;
  text-align: left;
  font-size: 32px;
}

.Team-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  row-gap: 60px;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .Team-main h2 {
    text-align: center;
  }

  .Team-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    row-gap: 60px;
    width: 100%;
    margin: 0;
  }
}
