.TeamDescription-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.TeamDescription-main h2 {
  width: 100%;
  text-align: left;
  font-size: 32px;
}

.TeamDescription-main p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-block-start: 0;
  margin-block-end: 7px;
  font-family: "Open Sans";
}

.TeamDescription-p {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .TeamDescription-main {
    margin-top: 30px;
  }

  .TeamDescription-main h2 {
    text-align: center;
  }
}
