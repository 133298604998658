.CaseStudies-main {
  height: 100%;
  padding: 100px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 60px;
}

.CaseStudies-main h1 {
  width: 100%;
  text-align: center;
  font-size: 32px;
}

@media (max-width: 768px) {
  .CaseStudies-main {
    height: 100%;
    padding: 30px;
  }
}
